import React, { Component, useState } from 'react';
import './labeltags.css';

export function LabelTags({allTags, item, valueChanged, newlyAddedTags, setNewlyAddedTags, linkedTags, setLinkedTags}) {

    const [tags, setTags] = useState([]);
    const [displayTags, setDisplayTags] = useState([]);
    const [tagInput, setTagInput] = useState('');

 
//during input change for tags, search existing tags and display matching results under input field    
function returnTagArray(e) {
    let enteredTag = e.target.value;
    let matchingTags = [];

     //comparing entered tag to array of all current tags in db and showing matching results
    for (let i = 0; i < allTags.length; i++){
    
        //If the entered tag matches existing tags, show the matching tags
        if (allTags[i].name.includes(enteredTag)) {
            matchingTags.push(allTags[i].name)
        };
    }
    setDisplayTags(matchingTags);
}

//function that gets called when a tag from the list of matching tags is clicked to add the clicked tag as a selected tag
function insertTag(event) {
    const element = event.target;
    const tag = element.innerText;
    
    //If tag is already entered, an alert will display
    for (let i=0; i < tags.length; i++) {
        if (tag === tags[i]) {
            return alert('That tag is already used. Please enter a unique tag.');
        }
    }
    setTags([...tags, tag]);

    //if clicked tag is already in the db, adds the id to linkedTags array
    for (let i=0; i < allTags.length; i++) {
        if (allTags[i].name == tag) {
            setLinkedTags([...linkedTags, allTags[i].id]);
        }
    }
   
    //add tags to the item
    // item.labelTags = tags;  // This breaks everything
     
    //set the input back to blank
    setTagInput('');
    setDisplayTags([]);
}

function handleKeyDown(e) {

    setTagInput(e.value);

    // Initialize variables
    const value = e.target.value.trim();
 
    let matchFound = false;
    const uniqueTags = [...newlyAddedTags];
  
    // If user didn't press Enter, return
    if (e.key !== 'Enter') return;
  
    // Validate tag format
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      alert('Tags must be letters or numbers only. Please remove invalid characters.');
      return;
    }
  
    // Check if tag already exists
    if (tags.includes(value)) {
      alert('That tag is already used. Please enter a unique tag.');
      return;
    }
  
    // Check if the tag exists in the database
    for (const tag of allTags) {
        if (tag.name === value) {
        matchFound = true;
        
        let updatedArray = linkedTags;
        updatedArray.push(tag.id);
        setLinkedTags(updatedArray);
        // console.log(linkedTags);
        break;
      }
    }
  
    // If no tag match found in db, add the tag to uniqueTags array
    if (!matchFound) {
      uniqueTags.push(value);
    }
  
    // Update state with uniqueTags
    setNewlyAddedTags(uniqueTags);
  
    // Add the value to the tags array
    setTags([...tags, value]);
  
    // Clear the input
    e.target.value = '';
  }

    function removeTag(index, tag) {
        setTags(tags.filter((el, i) => i !== index));

        //updates the newlyAddedTag state variable by removing any tags that were newly added and then removed from the UI
        for (let i=0; i < newlyAddedTags.length; i++){
            if (newlyAddedTags.includes(tag)) {
                let updatedArray = newlyAddedTags.filter(item => item !== tag);
                setNewlyAddedTags(updatedArray);
                return;
            }
        }

        //updates the linkedTags state variable by removing any tags that were newly added and then removed from the UI
        for (let i=0; i < allTags.length; i++) {
            if (allTags[i].name == tag) {
                let linkedIndex = allTags[i].id;
                let updatedArray = linkedTags.filter(item => item !== linkedIndex)
                setLinkedTags(updatedArray);
                return;
            }
        }
    }

   
    // console.log('nelwyadded: ', newlyAddedTags);
    // console.log('linkedTags: ', linkedTags);

    return (
        <> 
        <div className="tags-input-container" id='enteredTags'>

            {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text" id={'tagElement'} value={tag}>{tag}</span>
                    <span className="close" onClick={() => removeTag(index, tag)}>&times;</span>
                </div>
            ))}
            <input id='tagInput' onKeyDown={handleKeyDown} onChange={returnTagArray} type="text" className="tags-input" placeholder="" value={tagInput}/>
        </div>
        <div id='tagList' className='expanded_tag_container'>
            <ul>
            {displayTags.map((x, index) => 
               <li key={index} className='' onClick={insertTag}>{x}</li>
            )}
            </ul>
            </div>
    </>
    )
}